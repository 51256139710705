import * as importedMessages from "@/locales/translations.json";
import { datetimeFormats } from "@/entrypoints/shared/i18n_constants";
import { createI18n } from "vue-i18n";
import type { Locale } from "vue-i18n";

// If js i18n environment is not configured, we default to english
if ( !window.__i18n ) {
  window.__i18n = {
    locale: "en",
    available: ["en"],
    translations: { 
      en: {}
    },
  }
};

const messages = {};

// Dynamic import (just load messages for the enabled languages)
window.__i18n.available.forEach((locale: Locale) => {
  messages[locale] = importedMessages[locale];
});

const i18n = createI18n<false>({
  locale: window.__i18n ? window.__i18n.locale : "en",
  messages,
  fallbackLocale: "en",
  datetimeFormats,
  warnHtmlInMessage: "off",
});

// Merge in custom js translations
window.__i18n.available.forEach((locale: Locale) => {
  if (window.__i18n.translations[locale]) {
    i18n.global.mergeLocaleMessage(locale, window.__i18n.translations[locale]);
  }
});

export { i18n };

export default i18n;
